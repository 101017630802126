import { Stack, StackRow } from "codekit";
import Changpwd from "../../components/Changpwd/Changpwd";
import { useEffect, useState } from "react";
import Pwdrules from "../../components/Pwdrules/Pwdrules";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (e) => setMatches(e.matches);

    mediaQueryList.addEventListener("change", listener);
    return () => mediaQueryList.removeEventListener("change", listener);
  }, [query]);

  return matches;
};

const Chanpass = () => {

  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  return (
    <Stack columnsVariant="form-2">
      <StackRow>
        <Changpwd origin="LOGIN-PAGE" isSmallScreen={isSmallScreen} />
      </StackRow>
      {!isSmallScreen && (
        <StackRow>
          <Pwdrules />
        </StackRow>
      )}
    </Stack>
  )
}
export default Chanpass;
