/**
 * Slice para el maneja del estado Global para el componente RDO
 */
import { createSlice } from "@reduxjs/toolkit";

const resdocuxSlice = createSlice({
  name: "resdocux",
  initialState: {
    totalAdvanceOper: 0,
    totalRefunds: 0,
    totalSupplier: 0,
    totalInvoice: 0,
  },
  reducers: {
    setTotalAdvanceOper: (state, action) => {
      state.totalAdvanceOper = action.payload;
    },
    setTotalRefunds: (state, action) => {
      state.totalRefunds = action.payload;
    },
    setTotalSupplier: (state, action) => {
      state.totalSupplier = action.payload;
    },
    setTotalInvoice: (state, action) => {
      state.totalInvoice = action.payload;
    },
  },
});

export const { setTotalAdvanceOper, setTotalRefunds, setTotalSupplier, setTotalInvoice } = resdocuxSlice.actions;

export default resdocuxSlice.reducer;

export const gatAllStateRdo = (state) => {
  return state.resdocux;
};
