/**
 * Clase para establecer condiciones en los filltros
 */
export default class Filter {
  columns;
  filters;
  constructor(columns) {
    this.columns = columns;
    this.setFilters();
  }

  getTyeField = (column) => {
    return column.INCLUDEX
      ? column.INCLUDEX
      : column.TIPCAMXX !== "HORA" && column.TIPCAMXX !== "FECHA" && column.TIPCAMXX !== "NUMERICO"
      ? "IN"
      : "BETWEEN";
  };

  setFilters() {
    let filters = [];
    if (this.columns.length > 0) {
      let dataFilters = {};
      this.columns.forEach((column) => {
        let field = column?.NOMCOLXX?.split("_");
        if (field && field.length > 0) {
          let newField = field[0];
          if (!dataFilters[newField]) {
            dataFilters[newField] = {};
          }
          let stringCheck = this.getTyeField(column);
          if (!dataFilters[newField][stringCheck]) {
            dataFilters[newField][stringCheck] = [];
          }
          dataFilters[newField] = {
            ...dataFilters[newField],
            [stringCheck]:
              column.TIPCAMXX !== "HORA" &&
              column.TIPCAMXX !== "FECHA" &&
              column.TIPCAMXX !== "NUMERICO" &&
              column.TIPCAMXX !== "MASIVO"
                ? [...dataFilters[newField][stringCheck], column.VALUEXXX]
                : column.TIPCAMXX === "FECHA"
                ? this.getDate(column.VALUEXXX)
                : column.VALUEXXX,
          };
        }
      });
      let i = 0;
      for (let key in dataFilters) {
        filters[i] = { [`${key}`]: dataFilters[key] };
        i++;
      }
    }
    this.filters = filters;
  }

  getFilters() {
    return this.filters;
  }

  /**
   * Metodo para validar filtros esta se usa con la instancia de la clase
   */
  isValidFilters() {
    const haveEmpties = this.columns.find((col) => {
      let isInValid = false;
      if (typeof col.VALUEXXX === "object") {
        isInValid =
          col.VALUEXXX[0] === undefined ||
          col.VALUEXXX[1] === undefined ||
          col.VALUEXXX[0] === "" ||
          col.VALUEXXX[1] === ""
            ? true
            : false;
      }
      if (typeof col.VALUEXXX === "string") {
        isInValid = col.VALUEXXX === "" ? true : false;
      }
      return isInValid;
    });
    return haveEmpties === undefined;
  }

  /**
   * Metodo para validar filtros esta se puede usar sin necesidad de instaciar la clase
   */
  static isValidFilters(columns) {
    const haveEmpties = columns.find((col) => {
      let isInValid = false;
      if (typeof col.VALUEXXX === "object") {
        isInValid =
          col.VALUEXXX[0] === undefined ||
          col.VALUEXXX[1] === undefined ||
          col.VALUEXXX[0] === "" ||
          col.VALUEXXX[1] === ""
            ? true
            : false;
      }
      if (typeof col.VALUEXXX === "string") {
        isInValid = col.VALUEXXX === "" ? true : false;
      }
      return isInValid;
    });
    return haveEmpties === undefined;
  }

  /**
   * Metodo para obtener columnas segun un filtro
   */
  static getColumns(filters, columnsConsult) {
    let colsFilter = [];
    filters.forEach((value) => {
      const keys = Object.keys(value);
      columnsConsult.forEach((element) => {
        if (keys.includes(element.NOMCOLXX)) {
          let values = value[element.NOMCOLXX];
          Object.keys(values).forEach((operator, index) => {
            if (element.TIPCAMXX !== "FECHA" && element.TIPCAMXX !== "HORA" && element.TIPCAMXX !== "NUMERICO") {
              values[operator].forEach((val) => {
                if (index === 0) {
                  colsFilter = [...colsFilter, { ...element, VALUEXXX: val, INCLUDEX: operator }];
                } else {
                  colsFilter = [
                    ...colsFilter,
                    { ...element, NOMCOLXX: `${element.NOMCOLXX}_1`, VALUEXXX: val, INCLUDEX: operator },
                  ];
                }
              });
            } else {
              colsFilter = [...colsFilter, { ...element, VALUEXXX: values[operator], INCLUDEX: operator }];
            }
          });
        }
      });
    });
    return colsFilter;
  }

  //Funcion para el formato de fecha Y-m-d
  getDate = (date) => {
    if (date.length > 0) {
      let newDates = [];
      date.forEach((value) => {
        if (value !== "") {
          const today = new Date(value);
          const year = today.getFullYear();
          const month = `${today.getMonth() + 1}`.padStart(2, "0");
          const day = `${today.getDate()}`.padStart(2, "0");
          newDates.push([year, month, day].join("-"));
        } else {
          newDates.push(value);
        }
      });
      return newDates;
    }
    return "";
  };
}
