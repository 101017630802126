import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bugraphs } from "../components/Builders/Bugrafx/Bugraphs";
import { getDataUser, setTemporalUsers } from "../libs/redux/slices/authSlice/authSlice";
import { useGetGraphsQuery } from "../libs/redux/slices/Graficsx/graficsxApiSlice";
import { useGetUserTemporalQuery, useGetDashboardQuery } from "../libs/redux/slices/authSlice/authApiSlice";
import { TableLoader, BoxStatus } from "codekit";

function DashboardHome() {
  const dispatch = useDispatch();
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: graphs } = useGetGraphsQuery({ USRIDXXX, CARIDXXX });
  const { data: temporalUsers, isSuccess } = useGetUserTemporalQuery({ USRIDXXX });
  const { data: dataGraphics, isSuccess: isSuccessGraphics } = useGetDashboardQuery();

  //Hook para el seteo de los usuarios reemplazo
  useEffect(() => {
    if (isSuccess) {
      const { data = [] } = temporalUsers;
      dispatch(setTemporalUsers(data));
    }
  }, [isSuccess, temporalUsers, dispatch]);

  return isSuccessGraphics && graphs ? (
    dataGraphics.data.map((CIMS0036) => {
      if (CIMS0036.GRACOMXX === "TASKGRAF") {
        return <Bugraphs graphs={graphs.data} />;
      } else {
        let CurrentGraphic = lazy(() =>
          import(`../components/${CIMS0036.GRACOMXX}`).catch((error) => {
            console.error(error);
            return { default: () => "" };
          })
        );
        return <Suspense fallback={<TableLoader />}>{<CurrentGraphic />}</Suspense>;
      }
    })
  ) : (
    <BoxStatus variant="loading" text="Cargando información..." />
  );
}

export default DashboardHome;
