/**
 * Página External:
 * Gestiona la ruta {entorno}/external/{STRING}, descifra la URL, valida la sesión del usuario y redirige al menú proporcionado en el link.
 */

import { Box, Divider, BoxStatus } from "codekit";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useDecryptUrlQuery,
  useLoginUserExternalMutation,
  useLogoutMutation,
} from "../libs/redux/slices/authSlice/authApiSlice";
import { getisLoggedIn, getDataUser, signOut } from "../libs/redux/slices/authSlice/authSlice";
import { setModuleSelected, setProcesSelected } from "../libs/redux/slices/MenuSlice/menuSlice";
import { setFiltersTracking, setExternal, setFunctionToExecute  } from "../libs/redux/slices/trackingSlice/trackingSlice";
import { reset } from "../libs/redux/slices/MenuSlice/menuSlice";
import { resetConsult } from "../libs/redux/slices/consultSlice/consultSlice";
import { resetCustomFilters } from "../libs/redux/slices/trackingSlice/trackingSlice";

const External = () => {
  const { string } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const isLoggedIn = useSelector(getisLoggedIn);
  const infoUser = useSelector(getDataUser);
  const { data: decryptedData, error: decryptError, isLoading: decryptLoading } = useDecryptUrlQuery(string);
  const [loginUserExternal] = useLoginUserExternalMutation();

  /**
   * Funcion que Capitaliza la primera letra de una cadena.
   */
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  /**
   * Hook que maneja el proceso de inicio de sesión y redirección al módulo y proceso
   * enviados en el link cifrado
   */
  useEffect(() => {
    const handleLogin = () => {
      if (decryptedData) {
        const { USRIDXXX, MODURLXX, PRODURLX, jsonFilters, BANEJEFU, NOMFUNXX } = decryptedData.data;
        const filters = JSON.parse(jsonFilters);

        if (isLoggedIn && infoUser.USRIDXXX === USRIDXXX) {
          const selectedModule = infoUser.USERPERM.find(
            (menu) => menu.MODURLXX.toLowerCase() === MODURLXX.toLowerCase()
          );
          const selectedProcess = selectedModule?.CIMS0004.find(
            (process) => capitalizeFirstLetter(process.PRODURLX) === capitalizeFirstLetter(PRODURLX)
          );
          if (selectedModule && selectedProcess) {
            dispatch(setModuleSelected(selectedModule));
            dispatch(setProcesSelected(selectedProcess));
            dispatch(setFiltersTracking(filters));
            dispatch(setExternal(true));
            if (BANEJEFU === "SI" && NOMFUNXX) {
              const functionName = decryptedData.data.NOMFUNXX.toLowerCase();
              dispatch(setFunctionToExecute({ execute: true, functionName: functionName, ciacidxx: filters }));
            }
            setTimeout(() => {
              navigate(`/home`);
            }, 1000);
          }
        } else {
          if (infoUser && infoUser.USRIDXXX !== USRIDXXX) {
            logout()
              .unwrap()
              .then(() => {
                dispatch(signOut());
                dispatch(reset());
                dispatch(resetConsult());
                dispatch(resetCustomFilters());
              })
              .catch((error) => {
                console.error("Error durante el cierre de sesión:", error);
                navigate(`/login`);
              });
          }

          loginUserExternal({ USRIDXXX })
            .unwrap()
            .then((userResponse) => {
              const { INFOUSER } = userResponse.data[0];
              const selectedModule = INFOUSER.USERPERM.find(
                (menu) => menu.MODURLXX.toLowerCase() === MODURLXX.toLowerCase()
              );
              const selectedProcess = selectedModule?.CIMS0004.find(
                (process) => capitalizeFirstLetter(process.PRODURLX) === capitalizeFirstLetter(PRODURLX)
              );
              if (selectedModule && selectedProcess) {
                dispatch(setModuleSelected(selectedModule));
                dispatch(setProcesSelected(selectedProcess));
                dispatch(setFiltersTracking(filters));
                dispatch(setExternal(true));
                if (BANEJEFU === "SI" && NOMFUNXX) {
                  const functionName = decryptedData.data.NOMFUNXX.toLowerCase();
                  dispatch(setFunctionToExecute({ execute: true, functionName: functionName, ciacidxx: filters }));
                }
                setTimeout(() => {
                  navigate(`/home`);
                }, 1000);
              }
            })
            .catch(async (error) => {
              navigate(`/home`);
            });
        }
      }
    };

    handleLogin();
  }, [decryptedData, loginUserExternal, isLoggedIn, infoUser, navigate, dispatch, logout]);

  return (
    <>
      <Divider />
      <Box mt={2}>
        {decryptLoading ? (
          <BoxStatus variant="loading" text="Cargando información..." />
        ) : decryptError || !decryptedData ? (
          <BoxStatus variant="empty" text="Esta sección está vacía" />
        ) : (
          <BoxStatus variant="loading" text="Cargando información..." />
        )}
      </Box>
    </>
  );
};

export default External;
